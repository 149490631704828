import React from "react";

interface FormatAddressProps {
  hex: string;
  start?: number;
  end?: number;
  full?: boolean;
  className?: string;
}

const FormatAddress: React.FC<FormatAddressProps> = ({
  hex,
  start = 7,
  end = 6,
  className,
  full = false,
}) => {
  function formatAddress(): string {
    if (full) return hex;
    else if (hex) {
      const firstPart = hex.substring(0, start);
      const lastPart = hex.substring(hex.length - end);
      return `${firstPart}...${lastPart}`;
    } else {
      return "";
    }
  }

  return (
    <span className={`formatAddress ${className ? className : ""}`}>
      {formatAddress()}
    </span>
  );
};

export default FormatAddress;
