import "./Spinner.scss";

interface SpinnerProps {
  color?: `#${string}`;
}
const Spinner = ({ color }: SpinnerProps) => {
  return (
    <div className="spinnerContainer">
      <div className="spinner">
        <span
          className="ball-1"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className="ball-2"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className="ball-3"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className="ball-4"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className="ball-5"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className="ball-6"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className="ball-7"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className="ball-8"
          style={{
            backgroundColor: color,
          }}
        ></span>
      </div>
    </div>
  );
};

export default Spinner;
