import React, { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Link } from "../../../assets/svg/svgIcons";
import { NavItemType } from "../Navbar";
interface ElementNavBarProps {
  navItem: NavItemType;
  onLinkClick?: () => void;
}

const ElementNavBar: React.FC<ElementNavBarProps> = ({
  navItem,
  onLinkClick,
}) => {
  const location = useLocation();

  const match = () => {
    if (navItem.href?.split("/")[1] === location.pathname.split("/")[1]) {
      return true;
    }
    return false;
  };

  return (
    <li className={`nav-item ${navItem.className} ${match() ? "active" : ""}`}>
      <NavLink
        to={navItem.href ?? navItem.link ?? "/"}
        className={`nav-link `}
        onClick={onLinkClick}
        aria-current="page"
        end={navItem.href === "/"}
        target={navItem.link ? "_blank" : "_self"}
      >
        {navItem.icon && navItem.icon}
        <span className="link-text">
          {navItem.text}
          {navItem.link && <Link />}
        </span>
      </NavLink>
    </li>
  );
};

export default memo(ElementNavBar);
