import "./FormatNumber.scss";

import { ethers } from "ethers";
import React from "react";

interface FormatNumberProps {
  value: string | number | bigint;
  decimals?: number;
  toFixed?: number;
  alias?: string;
  colored?: boolean;
  className?: string;
}

const FormatNumber: React.FC<FormatNumberProps> = ({
  value,
  decimals, // TODO : change with assetValue .getReadableValue()
  toFixed = 2,
  alias,
  colored,
  className,
}) => {
  // Function to cut a number to the specified decimal places without rounding
  function cutToFixed(num: number, digits: number): string {
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${digits}})?`);
    const match = num.toString().match(regex);
    return match ? match[0] : num.toString();
  }

  // Function to format the number according to the provided decimals and format options
  function formatNumber(num: number): string {
    if (Number.isNaN(num)) return "0";

    const formattedValue = decimals
      ? Number(ethers.formatUnits(value, decimals))
      : num;
    let formattedNum = cutToFixed(formattedValue, toFixed)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,") // Add thousand separators
      .replace(/\.?0+$/, ""); // Remove trailing zeros

    return formattedNum.length >= 12 ? formattedNum.slice(0, 12) : formattedNum;
  }

  const formattedValue = formatNumber(Number(value));
  const isNegative = Number(value) < 0;
  const color = colored ? (isNegative ? "#F25050" : "#38C769") : "inherit";

  return (
    <span className={`formatNumberWrapper ${className ? className : ""}`}>
      <span className={`formatNumber`} style={{ color }} data-colored={colored}>
        {formattedValue}
      </span>
      {alias && (
        <span
          className={`formatNumberAlias`}
          style={{ color }}
          data-colored={colored}
        >
          &nbsp;
          {alias === "P" || alias === "JVM" ? "JUNE" : alias}
        </span>
      )}
    </span>
  );
};

export default FormatNumber;
