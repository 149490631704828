// useContractTransaction.ts
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import {
  Abi,
  Address,
  ContractFunctionExecutionError,
  EstimateGasExecutionError,
  TransactionExecutionError,
} from "viem";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";

import useLoadingStore from "../stores/LoadingStore";

export function useContractTransaction(chainId?: number) {
  const { setLoading } = useLoadingStore();
  const [error, setError] = useState<string | null>(null);
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient({ chainId });
  const { address } = useAccount();

  const executeTransaction = useCallback(
    async ({
      address,
      abi,
      functionName,
      args,
      value,
    }: {
      address: Address;
      abi: Abi;
      functionName: string;
      args: (string | number | number[][] | bigint)[];
      value?: bigint;
    }): Promise<string | null> => {
      // On retourne un hash ou null
      if (!walletClient) {
        toast.error("Wallet client not found");
        return null;
      }

      if (!publicClient) {
        toast.error("Public client not found");
        return null;
      }

      const argsToString = args.map((arg) => {
        if (typeof arg === "bigint") {
          return arg.toString();
        }
        return arg;
      });

      setLoading(functionName + argsToString, true);
      setError(null);

      try {
        // Estimate gas
        await publicClient.estimateContractGas({
          address,
          abi,
          functionName,
          args,
          account: walletClient.account,
          value,
        });

        // Execute transaction
        const hash = await walletClient.writeContract({
          address,
          abi,
          functionName,
          args,
          value,
        });

        if (hash) {
          const response = await publicClient.waitForTransactionReceipt({
            hash,
          });
          if (response.status === "reverted") {
            toast.error("Transaction failed");
            return null;
          }
          return hash;
        } else {
          return null;
        }
      } catch (e) {
        console.error(e);
        if (e instanceof EstimateGasExecutionError) {
          toast.error("Gas estimation failed");
        }
        if (e instanceof ContractFunctionExecutionError) {
          if (e.message.includes("User rejected the request.")) {
            toast.error("User rejected the request");
            return null; // Transaction rejetée par l'utilisateur
          }
          if (e.message.includes("insufficient funds for gas * price")) {
            toast.error("Insufficient funds for gas * price");
            return null;
          }
          const errorMessageMatch = e.message.match(/Error: ([^(]+)/);
          const errorMessage = errorMessageMatch
            ? errorMessageMatch[1].trim()
            : "Unknown error";
          toast.error(errorMessage);

          return null;
        }
        if (e instanceof TransactionExecutionError) {
          toast.error(e.shortMessage);
          return null;
        }

        const error = e as Error;
        setError(error.message);
        return null;
      } finally {
        setLoading(functionName + argsToString, false);
      }
    },
    [walletClient],
  );

  const estimateTransaction = useCallback(
    async ({
      address,
      abi,
      functionName,
      args,
      value,
    }: {
      address: Address;
      abi: Abi;
      functionName: string;
      args: (string | number | number[][] | bigint)[];
      value?: bigint;
    }): Promise<bigint | null> => {
      // On retourne un bigint ou null
      if (!walletClient) {
        toast.error("Wallet client not found");
        return null;
      }

      if (!publicClient) {
        toast.error("Public client not found");
        return null;
      }

      setError(null);

      try {
        // Estimate gas
        const estimation = await publicClient.estimateContractGas({
          address,
          abi,
          functionName,
          args,
          account: walletClient.account,
          value,
        });
        return estimation;
      } catch (e) {
        console.error(e);
        if (e instanceof EstimateGasExecutionError) {
          toast.error("Gas estimation failed");
        }

        const error = e as Error;
        setError(error.message);
        return null;
      }
    },
    [walletClient],
  );

  const executeReadContract = useCallback(
    async ({
      address,
      abi,
      functionName,
      args,
    }: {
      address: Address;
      abi: Abi;
      functionName: string;
      args: (Address | string | number | number[][] | bigint)[];
    }) => {
      if (!address) {
        return;
      }
      const result = await publicClient?.readContract({
        address: address,
        abi: abi,
        functionName: functionName,
        args: args,
      });

      return result;
    },
    [address, publicClient],
  );

  return {
    executeTransaction,
    executeReadContract,
    estimateTransaction,
    error,
  };
}
