import "./MintNFT.scss";

import { useAccount } from "wagmi";

import { Container, ContainerContent } from "../../mcn-react-ui";
import NFTList from "./NFTList/NFTList";
import WalletConnectButton from "./WalletConnectButton/WalletConnectButton";

const MintNFT = () => {
  const { isConnected, chain } = useAccount();
  return (
    <Container>
      <ContainerContent className="tokenDistributionPage">
        <WalletConnectButton />
        <div className="title">Connected to {chain?.name}</div>

        {isConnected && <NFTList />}
      </ContainerContent>
    </Container>
  );
};

export default MintNFT;
