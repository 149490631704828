import "./MobileNavbar.scss";

import React, { memo, useState } from "react";
import { NavLink } from "react-router-dom";

import { BurgerClose, BurgerOpen, Logo } from "../../../assets/svg/svgIcons";
import ElementNavBar from "../ElementNavbar/ElementNavbar";
import { NavItemType } from "../Navbar";

interface MobileNavbarProps {
  navItems: NavItemType[];
  children?: React.ReactNode;
}
const MobileNavbar: React.FC<MobileNavbarProps> = ({ navItems, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <nav
      className={`mobileNavbar`}
      role="navigation"
      aria-label="Site Navigation"
    >
      <div className="navbar-toggler">
        <NavLink to="/">
          <Logo />
        </NavLink>
        {collapsed ? (
          <BurgerClose onClick={toggleNavbar} className={`burger-close-btn`} />
        ) : (
          <BurgerOpen onClick={toggleNavbar} className={`burger-open-btn`} />
        )}
      </div>
      <div className={`navbar-collapse ${collapsed ? "show" : ""}`}>
        <ul className="navbar-nav">
          {navItems.map((item) => (
            <ElementNavBar
              key={item.href + item.text}
              navItem={item}
              onLinkClick={() => setCollapsed(false)}
            />
          ))}
        </ul>
        {/* pass toggleNavbar to children */}
        {children &&
          React.cloneElement(children as React.ReactElement, { toggleNavbar })}
      </div>
    </nav>
  );
};

export default memo(MobileNavbar);
