import { Home } from "../../assets/svg/svgIcons";
import { NavItemType } from "../../mcn-react-ui/Navbar/Navbar";

const navItems: NavItemType[] = [
  {
    href: "/",
    text: "Track NodeId",
    icon: <Home />,
    className: "latestValidators",
  },
  {
    href: "/latestValidators",
    text: "Latest Validators",
    icon: <Home />,
    className: "latestValidators",
  },
  {
    href: "/trackValidator",
    text: "Track Validator",
    icon: <Home />,
    className: "trackValidator",
  },
  {
    href: "/networkData",
    text: "Network Data",
    icon: <Home />,
    className: "networkData",
  },
  {
    href: "/juneoValidators",
    text: "Juneo Validators",
    icon: <Home />,
    className: "networkData",
  },
  {
    href: "/mintNFT",
    text: "Mint NFT",
    icon: <Home />,
    className: "networkData",
  },
  {
    href: "/tokenDistribution",
    text: "Token Distribution",
    icon: <Home />,
    className: "networkData",
  },
  // {
  //   href: "/sendToken",
  //   text: "Send Token",
  //   icon: <Home />,
  //   className: "networkData",
  // },
];

export default navItems;
