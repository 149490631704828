import "./NFTList.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Abi, Address, parseEther } from "viem";
import { useAccount } from "wagmi";

import contractAbi from "../../../abi/erc721.json";
import { useContractTransaction } from "../../../hooks/useContractTransaction";
import { Button, Card } from "../../../mcn-react-ui";
import useLoadingStore from "../../../stores/LoadingStore";

type NFT = {
  id: string;
  metadataURI: string;
  name?: string;
  image?: string;
  description?: string;
};

const nfts: NFT[] = [
  {
    id: "1",
    metadataURI:
      "https://ipfs.io/ipfs/QmaHJE6fnmWY3UY6ciaf37DhcN8NRtkLmwowkSA2WJUqjP", // Bright-Yellow-Shocked.json
  },
  {
    id: "2",
    metadataURI:
      "https://ipfs.io/ipfs/QmQsh5AzRyh6nPFf62LXknQSq7fFWTMPieuBBbsgtSpYv6", // Light-Blue-Curious.json
  },
  {
    id: "3",
    metadataURI:
      "https://ipfs.io/ipfs/Qmd4E9b6ECrkD3cwNQYgT3ntJXrFEhzdq617FVnDsTfe33", // Yellow-Concerned.json
  },
  {
    id: "4",
    metadataURI:
      "https://ipfs.io/ipfs/QmZB8M5RWmd1vsT4i8Yifw6D3F4dqb4CAE22SLSwebt2SQ", // Dark-Blue-Hangry.json
  },
  {
    id: "5",
    metadataURI:
      "https://ipfs.io/ipfs/QmUVjCfZGqwNgy9Yk3ezNMCa6Q59SwmisWsu2H1o5xi1WW", // Light-Blue-Sad.json
  },
  {
    id: "6",
    metadataURI:
      "https://ipfs.io/ipfs/QmVcSeZuw6z6GVj3DXpC6uVXz4YW219gi66YAYpZMn7VB8", // Blue-Playful.json
  },
  {
    id: "7",
    metadataURI:
      "https://ipfs.io/ipfs/QmP8HtUsVGRhNFQhNuMqvozsMKBQh2HLsDg63SoYLM47KL", // Orange-Stern.json
  },
  {
    id: "8",
    metadataURI:
      "https://ipfs.io/ipfs/QmT4hHqyhMspfLM54EJEAWmEzVM1MPvoPcaeo5R6TPfSRr", // Blue-Joyful.json
  },
  {
    id: "9",
    metadataURI:
      "https://ipfs.io/ipfs/QmP79ovKwj6yJ9Rc1aHr6w2wB5tLtTJLjw5TCjnDDGc7JF", // Green-Happy.json
  },
];

const NFTList = () => {
  const [nftData, setNftData] = useState<NFT[]>([]);
  const { executeTransaction } = useContractTransaction();
  const { loadingStates } = useLoadingStore();
  const { isConnected, chain } = useAccount();

  const [hash, setHash] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      const updatedNfts = await Promise.all(
        nfts.map(async (nft) => {
          const response = await fetch(nft.metadataURI);
          const metadata = await response.json();
          return {
            ...nft,
            name: metadata.name,
            image: metadata.image,
            description: metadata.description,
          };
        }),
      );
      setNftData(updatedNfts);
    };

    fetchMetadata();
  }, []);

  const handleMint = async (uri: string) => {
    if (!isConnected) {
      console.error("User is not connected.");
      return;
    }
    setHash(null);
    const nftContract = chain?.contracts?.nft;

    const nftContractAddress =
      typeof nftContract === "object" && "address" in nftContract
        ? nftContract.address
        : undefined;

    if (!nftContractAddress) {
      console.error("No NFT contract address available.");
      return;
    }

    const hash = await executeTransaction({
      address: nftContractAddress as Address,
      abi: contractAbi as Abi,
      functionName: "mintNFTs",
      args: [uri],
      value: parseEther("0.1"),
    });

    if (hash) {
      setHash(hash);
      toast.success("Transaction sent");
    }
  };

  return (
    <>
      <div className="nftList">
        {nftData.map((nft) => (
          <Card key={nft.id} className="nftCard">
            <img src={nft.image} alt={nft.name} />
            <h3>{nft.name}</h3>
            <Button
              onClick={() => handleMint(nft.metadataURI)}
              label="Mint NFT"
              isLoading={loadingStates["mintNFTs" + nft.metadataURI]}
            />
          </Card>
        ))}
      </div>
      {hash && (
        <div className="transactionHash">
          <a
            href={chain?.blockExplorers?.default?.url + "/tx/" + hash}
            target="_blank"
            rel="noreferrer"
          >
            Transaction hash: {hash}
          </a>
        </div>
      )}
    </>
  );
};

export default NFTList;
