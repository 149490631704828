import "./DesktopNavbar.scss";

import { memo } from "react";
import { NavLink } from "react-router-dom";

import { Logo } from "../../../assets/svg/svgIcons";
import ElementNavBar from "../ElementNavbar/ElementNavbar";
import { NavItemType } from "../Navbar";

interface DesktopNavbarProps {
  navItems: NavItemType[];
  children?: React.ReactNode;
}

const DesktopNavbar = ({ navItems, children }: DesktopNavbarProps) => {
  return (
    <nav
      className={`desktopNavbar`}
      role="navigation"
      aria-label="Site Navigation"
    >
      <div className={`navbar-collapse`}>
        <ul className="navbar-nav">
          <li className="navTitleDiv">
            <NavLink to="/">
              <Logo className="navTitle" />
            </NavLink>
          </li>
          {navItems.map((item) => (
            <ElementNavBar key={item.href + item.text} navItem={item} />
          ))}
        </ul>
        {children}
      </div>
    </nav>
  );
};

export default memo(DesktopNavbar);
