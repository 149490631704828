import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { http } from "wagmi";

import logo from "../assets/img/june.png";
import { JUNE, socotraJUNE, socotraUSDT1 } from "../config/chainsConfig";

const projectId = "98d42923be489603e3885e07711e4208";

const metadata = {
  name: "MCN Bridge",
  description: "MCN Bridge",
  url: "https://bridge.juneo.com",
  icons: [logo],
};

const chains = [JUNE, socotraJUNE, socotraUSDT1] as const;

export const wagmiConfig: any = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableWalletConnect: true,
  enableInjected: true,
  enableEIP6963: true,
  auth: {
    email: false,
    socials: [], // if we want to enable socials, we can add them here
    showWallets: true,
    walletFeatures: true,
  },
  enableCoinbase: true,
  transports: {
    [socotraJUNE.id]: http(),
    [socotraUSDT1.id]: http(),
    [JUNE.id]: http(),
  },
});

createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId,
  enableAnalytics: true,
  themeVariables: {
    "--w3m-z-index": 120,
  },
  chainImages: {
    [socotraJUNE.id]: logo,
  },
});
